import { Config } from '@comall-backend-builder/core/lib/parser';
import { OFFLINE_ORDER_STATUS_PART_OPTIONS, OFFLINE_DELIVERY_TYPE_OPTIONS } from '@/constants';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        offlineOrderList: {
            apiPath: '/loader/dc-order/admin/offlineOrder',
            filters: {
                pickStoreName: {
                    type: 'string',
                    displayName: '<<afterSale.filters.receiveAddress>>',
                    controlConfig: {
                        placeholder: '<<afterSale.filters.receiveAddressPlaceholder>>',
                    },
                },
                outerOrderNumber: {
                    type: 'string',
                    displayName: '<<order.outerOrderNumber>>',
                    controlConfig: {
                        placeholder: '<<afterSale.filters.orderNumberPlaceholder>>',
                    },
                },
                orderStatus: {
                    type: 'string.options.select',
                    displayName: '<<afterSale.afterSaleOrderStatus>>',
                    options: OFFLINE_ORDER_STATUS_PART_OPTIONS,
                    controlConfig: {
                        allowClear: true,
                    },
                },
                keyword: {
                    type: 'string.text.trim',
                    displayName: '<<keyword>>',
                    controlConfig: {
                        placeholder: '<<afterSale.filters.keyWordsPlaceHolder>>',
                    },
                },
                createTime: {
                    type: 'object.dateRange',
                    displayName: '<<order.createTime>>',
                    controlConfig: {
                        style: { width: '100%' },
                    },
                },
                deliveryFlag: {
                    type: 'string.options.select',
                    displayName: '<<deliveryType.title>>',
                    options: OFFLINE_DELIVERY_TYPE_OPTIONS,
                    controlConfig: {
                        allowClear: true,
                    },
                },
                paid: {
                    type: 'string.options.select',
                    displayName: '<<retailStore.paid>>',
                    options: [
                        { id: '0', name: services.language.getText('productList.no') },
                        { id: '1', name: services.language.getText('productList.yes') },
                    ],
                },
                merchantId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-goods/admin/merchant',
                    },
                    controlConfig: {
                        allowClear: true,
                    },
                },
            },
        },
    },
    components: {
        OfflineOrderContainer: {
            component: 'Viewport',
            entity: 'offlineOrderList',
        },
        OfflineOrderManagement: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OfflineOrderTabsFilter',
                    frequency: 30,
                },
            ],
            privilege: {
                path: 'offline.offline_order.offline_order_list',
                level: 'full',
            },
        },
    },
};
