import {
    ORDER_STATUS_PART_OPTIONS,
    OFFLINE_DELIVERY_TYPE_OPTIONS,
    MEMBER_TYPE_OPTIONS,
    STOCK_OUT_OPTIONS,
} from '@/constants';

export const filterProperties = {
    pickStoreName: {
        type: 'string',
        displayName: '<<afterSale.filters.receiveAddress>>',
        controlConfig: {
            placeholder: '<<afterSale.filters.receiveAddressPlaceholder>>',
        },
    },
    outerOrderNumber: {
        type: 'string',
        displayName: '<<order.outerOrderNumber>>',
        controlConfig: {
            placeholder: '<<afterSale.filters.orderNumberPlaceholder>>',
        },
    },
    orderNumber: {
        type: 'string',
        displayName: '<<order.orderNumber>>',
        controlConfig: {
            placeholder: '<<defaultPlaceholderInput>>',
        },
    },
    channels: {
        type: 'array.optionIds.select',
        displayName: '<<channel>>',
        options: [],
        source: {
            apiPath: '/loader/dc-master-data/admin/distribution_channels/findAllByLoginUser/order',
        },
        controlConfig: {
            allowClear: true,
            showArrow: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    orderStatus: {
        type: 'string.options.select',
        displayName: '<<order.orderStatus>>',
        options: ORDER_STATUS_PART_OPTIONS,
        controlConfig: {
            allowClear: true,
            showArrow: true,
        },
    },
    keyword: {
        type: 'string.text.trim',
        displayName: '<<keyword>>',
        controlConfig: {
            placeholder: '<<afterSale.filters.keyWordsPlaceHolder>>',
        },
    },
    onlineStoreIds: {
        type: 'array.treeNodeIds.tree',
        displayName: '<<order.onlineStore>>',
        options: [],
        source: {
            apiPath: '/loader/dc-store/admin/onlineStore/own',
        },
        controlConfig: {
            showArrow: true,
            allowClear: true,
            treeCheckable: true,
            treeNodeFilterProp: 'name',
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    pickStoreIds: {
        type: 'array.treeNodeIds.tree',
        displayName: '<<order.pickStore>>',
        options: [],
        source: {
            apiPath: '/dc-store/admin/pickStore/findPickStoreTree',
        },
        controlConfig: {
            allowClear: true,
            showArrow: true,
            treeCheckable: true,
            treeNodeFilterProp: 'name',
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    belongStoreIds: {
        type: 'string',
        displayName: '<<order.orderOwnStore>>',
        controlConfig: {
            placeholder: '<<defaultPlaceholderInput>>',
        },
    },
    createTime: {
        type: 'object.dateRange',
        displayName: '<<order.createTime>>',
        controlConfig: {
            allowClear: true,
            showArrow: true,
            style: { width: '100%' },
        },
    },
    waitDeliveryTime: {
        type: 'object.dateRange',
        displayName: '<<order.waitDeliveryTime>>',
        controlConfig: {
            allowClear: true,
            showArrow: true,
            style: { width: '100%' },
        },
    },
    txnOrderNumber: {
        type: 'string.text.trim',
        displayName: '<<order.txnOrderNumber>>',
        controlConfig: {
            placeholder: '<<defaultPlaceholderInput>>',
        },
    },
    deliveryFlag: {
        type: 'string.options.select',
        displayName: '<<deliveryType.title>>',
        options: OFFLINE_DELIVERY_TYPE_OPTIONS,
        controlConfig: {
            allowClear: true,
            showArrow: true,
        },
    },
    merchantId: {
        type: 'string.options.autoComplete',
        displayName: '<<merchant>>',
        options: [],
        source: {
            apiPath: '/loader/dc-goods/admin/merchant/byUser',
        },
        controlConfig: {
            allowClear: true,
            showArrow: true,
        },
    },
    memberType: {
        type: 'string.options.select',
        displayName: '<<afterSale.memberType>>',
        options: MEMBER_TYPE_OPTIONS,
        controlConfig: {
            allowClear: true,
            showArrow: true,
        },
    },
    stockOut: {
        type: 'string.options.select',
        displayName: '<<order.stockOut>>',
        options: STOCK_OUT_OPTIONS,
        controlConfig: {
            allowClear: true,
            showArrow: true,
        },
    },
    payType: {
        type: 'string.options.autoComplete',
        displayName: '<<order.payType>>',
        options: [],
        source: {
            loadOnInit: false,
            apiPath: '/loader/base-order/admin/payment_type/byStoreId',
        },
        controlConfig: {
            allowClear: true,
            showArrow: true,
        },
    },
    expressDeliveryTime: {
        type: 'object.dateRange',
        displayName: '<<order.orderListItem.expressDeliveryTime>>',
        controlConfig: {
            allowClear: true,
            showArrow: true,
            style: { width: '100%' },
        },
    },
};
